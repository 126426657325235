@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body{
  margin: 50px 15%;
}
.App {
  text-align: center;
}

.body{
  display:flex;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: #3E6B89;
}
h1{
  font-family: "Hammersmith One", sans-serif;
  text-transform: uppercase;
  font-size:4rem;
  margin-bottom: 0;
  color: #3DD2CC;
  letter-spacing:0.3rem;
}

h2 {
  font-family: "Hammersmith One", sans-serif;
  text-transform: uppercase;
  color: #3E6B89;
  margin-top:0;
  font-weight: 400;
  letter-spacing: 0.3rem;
}

h3{
  font-family: "Hammersmith One", sans-serif;
  text-transform: uppercase;
  color: #3DD2CC;
  font-size:1.6rem;
  letter-spacing:0.3rem;
  margin-bottom: 0;
}

h4{
  font-family: "Hammersmith One", sans-serif;
  color: #3E6B89;
}
h5{
  font-family: "Hammersmith One", sans-serif;
  color: #3E6B89;
  font-size:1rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}





.subtitle {
  color: #3dd2cc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
}

ul {
  list-style: none;
}

.timeline{  
  li {
    position: relative;
    padding: 0 10px 20px 10px;
  }
  li a {
    color: gray;
  }
  li:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #3E6B89;
    position: absolute;
    left: -12px;
    top: 10px;
    transform: translateY(-40%);
    z-index:2;
  }

  li:after {
    content: '';
    width: 3px;
    height: 100%;
    background: #3dd2cc;
    position: absolute;
    left: -8px;
    top: 10px;
  }
  li:last-child:after{
    height:70%
  }
}

#jobs{
  width:70%;
  h3{
    margin-left: 26px;
  }
  .subtitle {
    display:flex;
    justify-content: space-between;
    margin-bottom:5px;
    .period{
      text-align: right;
    }
  }
}

header{
  border-bottom: 5px solid #3dd2cc;
}

.sidebar {
  width:30%;
  & > section{
    margin-bottom:30px;
  }
  ul{
    margin-bottom: 0;
  }
}

#contact{
  div{
    margin:12px 0;
  }
  .contact-button{
    cursor:pointer;
    text-decoration: underline;
  }
}

#education{
  ul{
    padding-left: 14px;
  }
  .years{
    display:block;
    color:#3DD2CC;
    font-size:0.8rem;
    margin-bottom:6px;
  }
}

#contact {
  svg {
    margin-right: 20px;
    color: #3dd2cc;
    font-size: 1.2rem;
    min-width: 1.2rem;
  }
  a, a:hover, a:visited, a:link, a:active{
    color: #3E6B89;
  }
}

#technologies {
  ul {
    padding-left: 0;
  }
  h4 {
    margin-bottom: 0;
    margin-top:5px;
    color: #3dd2cc;
    font-weight: 400;
    font-weight: 0.8rem;
    font-family: "Montserrat", sans-serif;
  }
  strong{
    font-weight: 500;
  }
}

#skills{
  ul {
    padding-left: 0;
  }
}

.details {
  i {
    color: #3dd2cc;
    cursor:pointer;
  }
}

@media (max-width: 1000px) {
  body{
    margin: 50px 7%;
  }
  .body{
    //display:block;
    flex-wrap:wrap;
  }
  .sidebar {
    width:100%;
    order: 2;
    display:flex;
    flex-wrap: wrap;
    // gap: 40px;
    
    & > section{
      flex: 50%;
      p{
        padding-right:30px;
      }
    }
  }
  #jobs {
    width:100%;
    order: 1;
  }
  .modal{
    .content{
        width: 90%;
        .form {
          margin: 40px 0;
          width:90%;
          label {
            min-width: 60px;
          }
        }
    }
  }
}

@media (max-width: 380px) {
  h1{
    font-size:3rem;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3DD2CC; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.feedback{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  a { 
    color:#3E6B89;
  }
}
.bullets{
  li {
    position: relative;
    padding-top: 4px;
    padding-left: 24px;
  }
  li:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #3E6B89;
    position: absolute;
    left: 0;
    top: 12px;
    transform: translateY(-40%);
    z-index:2;
  }
}
.modal{
  display: none;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  &-feedback{
    margin: 50px 0;
    color: #3E6B89;
    a {
      color: #3DD2CC;
    }
  }
}

/* Modal Content/Box */
.content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form {
  width:70%;
  margin: 20px 15% 60px 15%;
  display: block;
  &.hidden{
    display: none;
  }
  .formItem{
    display: flex;
    margin-bottom: 10px;
    label {
      min-width: 100px;
      text-align: right;
      padding-right:20px;
      color: #3E6B89;
    }
    input{
      width: 100%;
      min-height: 30px;
    }
    textarea {
      width: 100%;
      min-height: 80px;
    }
  }
  .actions{
    button {
      float:right;
      background:#3DD2CC;
      font-weight: bold;
      color:white;
      border:0;
      border-radius: 3px;
      padding:8px 15px;
      &:hover{
        background: rgb(51, 175, 171);
        color:white;
        cursor:pointer;
      }
    }  
  }
}